export default {
  beforeMount(el, binding) {
    if (binding.value === 'all') {
      document.getElementById('site-footer-app').classList.add('js-is-hidden');
    } else {
      document.getElementById('site-footer-app').classList.add('mobile-hidden');
    }
  },
  beforeUnmount(el, binding) {
    if (binding.value === 'all') {
      document.getElementById('site-footer-app').classList.remove('js-is-hidden');
    } else {
      document.getElementById('site-footer-app').classList.remove('mobile-hidden');
    }
  }
};
